<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="hasError"
      @dismissed="hasError = false"
    >
      Une erreur est survenue lors du téléchargement de vos fichiers ! Veuillez
      réessayer !
    </b-alert>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <div class="card-label">
            <div class="font-weight-bolder">Téléchargement</div>
          </div>
        </div>
      </div>
      <!--begin::Body-->
      <perfect-scrollbar
        class="scroll card-body text-dark-50 font-size-lg"
        style="max-height: 500px; position: relative;"
      >
        <!-- EmailForm -->
        <template v-if="url != null">
          Vos fichiers sont prêts à être téléchargés ! <br /><br />
          Si le téléchargement ne débute pas automatiquement, veuillez cliquer
          <a :href="url" target="_blank">ici</a>.
        </template>
        <template v-else>
          Vos fichiers sont bientôt prêts ! <br /><br />
          <div class="d-flex align-items-center">
            <b-spinner></b-spinner>
            <div class="ml-4">Veuillez patienter...</div>
          </div>
        </template>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { delay } from "../../../../constApplication";

export default {
  name: "Download",
  beforeRouteEnter(to, from, next) {
    if (to.query && to.query.from && to.query.to && to.query.code) {
      next(vm => {
        vm.getUrlDownload(to.query.from, to.query.to, to.query.code);
      });
    } else {
      next({ name: "dashboard" });
    }
  },
  data() {
    return {
      url: null,
      hasError: false
    };
  },
  methods: {
    async getUrlDownload(from, to, code) {
      this.hasError = false;
      this.url = null;
      await delay(2000);
      try {
        const response = await this.$services.transferService.download(
          from,
          to,
          code
        );
        if (response && response.data) {
          this.url = response.data;
          location.href = response.data;
        } else {
          this.hasError = true;
        }
      } catch {
        this.hasError = true;
      }
    }
  }
};
</script>
